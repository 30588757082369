import React from 'react';

const sizes = {
  width: "1.76vw", 
  height: "1.81vw"
}

const RadioCheckedIcon = () => {	return (
<svg style={sizes} viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="19.5" cy="17.5" r="17.5" fill="#80CC5E"/>
<path d="M13.048 16.7929C12.6575 16.4023 12.0244 16.4023 11.6338 16.7929C11.2433 17.1834 11.2433 17.8166 11.6338 18.2071L13.048 16.7929ZM17.1137 22.2727L16.4066 22.9798C16.5941 23.1673 16.8485 23.2727 17.1137 23.2727C17.3789 23.2727 17.6332 23.1673 17.8208 22.9798L17.1137 22.2727ZM26.5708 14.2298C26.9613 13.8393 26.9613 13.2061 26.5708 12.8156C26.1803 12.4251 25.5471 12.4251 25.1566 12.8156L26.5708 14.2298ZM11.6338 18.2071L16.4066 22.9798L17.8208 21.5656L13.048 16.7929L11.6338 18.2071ZM17.8208 22.9798L26.5708 14.2298L25.1566 12.8156L16.4066 21.5656L17.8208 22.9798Z" fill="white"/>
<defs>
<filter id="filter0_d_222_4596" x="0.409091" y="0" width="38.1818" height="39.7727" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="3.18182"/>
<feGaussianBlur stdDeviation="0.795455"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_222_4596"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_222_4596" result="shape"/>
</filter>
</defs>
</svg>
)
}

export default RadioCheckedIcon;

