import React, {useEffect,useCallback} from 'react';
import PropTypes from 'prop-types';
import {
  TextField
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

function useCombinedRefs(...refs) {
  const targetRef = React.useRef()

  useEffect(() => {
    refs.forEach(ref => {
      if (!ref) return

      if (typeof ref === 'function') {
        ref(targetRef.current)
      } else {
        ref.current = targetRef.current
      }
    })
  }, [refs])

  return targetRef
}

const InputNumber = React.forwardRef((props, ref) => {
  const { onChange, currencyFormat, rateFormat, inputComponent: inputComponentProp, ...field } = props;
  const innerRef = React.useRef(null)
  const combinedRef = useCombinedRefs(ref, innerRef)

  const handleNumberChange = ({target}) => {
    const {value:num} = target;
    const value = (''+num) !== '' && Number(num) > 0 ? Number(num) : '';
    onChange(value);
    clearInputValue(value);
  };

  const clearInputValue = useCallback((value) => {
    if((''+value) === '' || (''+value) === 'null' || (''+value) === 'undefined') {
      const input = combinedRef.current.querySelector('input');
      const inputValue = input?.value;
      if(inputValue.length) {
        input.value = '';
      }
    }
  }, [combinedRef])
  
  useEffect(() => {
    clearInputValue(field.value);
  },[clearInputValue, field.value]);

  return (<div ref={combinedRef}>
    <TextField
      fullWidth
      {...field}
      autoComplete='off'
      variant='outlined'
      onChange={handleNumberChange}
      InputProps={{
        endAdornment: <InputAdornment sx={{ marginRight: '0.5vw' }} position="start">₽</InputAdornment>,
      }}
    />
  </div>);
});

InputNumber.propTypes = {
  handleChange: PropTypes.func,
  value: PropTypes.number
};

export default InputNumber;
