/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import Register from './views/Register';
import Login from './views/Login';
import AddingParkingSpaces from './views/AddingParkingSpaces';
import Parking from './views/Parking';
import Analytics from './views/Analytics';
import Start from './views/Start/Start';
import Settings from './views/Settings';
import Deals from './views/Deals';
import ResetPassword from './views/ResetPassword';
import ProtectedRoute from './components/ProtectedRoute';
import { NoMatch } from './views/Other';

// import DashboardLayout from './layouts/Dashboard';

// const routes = [
  // {
  //   path: '/',
  //   exact: true,
  //   component: () => <HomeRoute />
  // },

//   {
//     path: '/auth',
//     component: AuthLayout,
//     routes: [
//       {
//         path: '/auth/login',
//         exact: true,
//         component: lazy(() => import('views/Login'))
//       },
//       {
//         path: '/auth/forgot-password',
//         exact: true,
//         component: lazy(() => import('views/ForgotPassword'))
//       },
//       {
//         path: '/auth/reset-password',
//         exact: true,
//         component: lazy(() => import('views/ResetPassword'))
//       },
//       {
//         path: '/auth/register',
//         exact: true,
//         component: lazy(() => import('views/Register'))
//       },
//       {
//         component: () => <Redirect to="/errors/error-404" />
//       }
//     ]
//   },
//   {
//     path: '/errors',
//     component: ErrorLayout,
//     routes: [
//       {
//         path: '/errors/error-401',
//         exact: true,
//         component: lazy(() => import('views/Error401'))
//       },
//       {
//         path: '/errors/error-404',
//         exact: true,
//         component: lazy(() => import('views/Error404'))
//       },
//       {
//         path: '/errors/error-500',
//         exact: true,
//         component: lazy(() => import('views/Error500'))
//       },
//       {
//         component: () => <Redirect to="/errors/error-404" />
//       }
//     ]
//   },
  // {
  //   path: '*',
  //   element: DashboardLayout,
  //   children: [
  //     {
  //       path: '/parking',
  //       element: <Parking />,
  //       children: [
  //         {
  //           path: '/parking/basic-information',
  //           exact: true,
  //           element: <ParkingBasicInfo/>//<AuthRoute><ToDoTasks /></AuthRoute>
  //         },
  //         {
  //           path: '/parking/payment',
  //           exact: true,
  //           element: <ParkingPayment/>//<AuthRoute><ToDoTasksCompleted /></AuthRoute>
  //         }
  //       ],
  //     },
  //       // default to
  //     {
  //       element: () => <Navigate to="/errors/error-404" />
  //     }
  //   ]
  // },
// ];

let routes = [
  { index: true, element: <ProtectedRoute><Start /></ProtectedRoute> },
  {
    path: '/register',
    element: <ProtectedRoute><Register /></ProtectedRoute>
  },
  {
    path: '/login',
    element:  <ProtectedRoute><Login /></ProtectedRoute>
  },
  {
    path: '/reset-password',
    element:  <ProtectedRoute><ResetPassword /></ProtectedRoute>
  },
  {
    path: '/adding-parking-spaces/*',
    element: <ProtectedRoute auth><AddingParkingSpaces /></ProtectedRoute>
  },
  {
    path: "/analytics",
    element: <ProtectedRoute auth><Analytics /></ProtectedRoute>,
  },
  {
    path: "/parking",
    element: <ProtectedRoute auth><Parking /></ProtectedRoute>
  },
  {
    path: "/settings",
    element: <ProtectedRoute auth><Settings /></ProtectedRoute>
  },
  {
    path: "/deals",
    element: <ProtectedRoute auth><Deals /></ProtectedRoute>
  },
  { path: "*", element: <NoMatch /> },
];

export default routes;
