import { styled } from '@mui/styles';
import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { HEIGHT_TOPBAR } from './../../constants/appConst';

const StyledPage = styled('div')({
  width: '100%',
  minHeight: `calc(100vh - ${HEIGHT_TOPBAR}px)`
})

const Page = props => {
  const { title, children, ...rest } = props;

  return (
    <StyledPage {...rest}>
      <HelmetProvider>
        <Helmet>
          <title>{title}</title>
        </Helmet>
      </HelmetProvider>
      {children}
    </StyledPage>
  );
};


export default Page;
