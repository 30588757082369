import React from 'react';

const sizes = {
  width: "1.76vw", 
  height: "1.81vw"
}

const RadioIcon = () => {	return (
<svg style={sizes} viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="19.5" cy="17.5" r="16.8461" stroke="white" strokeWidth="1.30777" shapeRendering="auto"/>
<circle cx="19.5" cy="17.5" stroke="white" strokeWidth="1.30777" shapeRendering="auto" fill="url(#paint25_linear_221_4053)" r="16.3"></circle>
<defs>
<linearGradient id="paint25_linear_221_4053" x1="953.26" y1="657.152" x2="943.613" y2="657.152" gradientUnits="userSpaceOnUse">
<stop stopColor="#4F23AC" offset="0"></stop>
<stop stopColor="#6135BC"></stop>
</linearGradient>
<filter id="filter0_d_222_4612" x="0.409091" y="0" width="38.1818" height="39.7727" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="3.18182"/>
<feGaussianBlur stdDeviation="0.795455"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_222_4612"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_222_4612" result="shape"/>
</filter>
</defs>
</svg>
)
}

export default RadioIcon;

