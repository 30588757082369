import React from "react";
import { Box, Typography, RadioGroup, FormControlLabel, Radio, FormHelperText } from "@mui/material";
import {useFormContext, Controller} from 'react-hook-form';
import AccessSystemLabel from './AccessSystemLabel';
import { defaultRequired } from "./../../rules";
import {COLOR_PURPLE} from '../../../../constants/appConst';
import { 
  DigitalCheckIcon, 
  OwnIcon, 
  QRCodeIcon, 
  RadioCheckedIcon, 
  RadioIcon 
} from "../../SVGs";

const AccessSystem = () => {
  const {control, watch} = useFormContext();
  // const [value, setValue] = useState(null);
  const watchAccessSystemType = watch('accessSystemType');


  // const handleRadioChange = (event) => {
  //   console.log(event)
  //   const {target} = event;
  //   setValue(target.checked ? target.value : null);
  // }

  const textDigitalCheck = 'При бронировании и оплате парковочного места через приложение PARKIFY, участники сделки получают цифровые чеки со следующими данными: дата, время и сумма оплаты, период аренды, государственный номер автомобиля.'
  const textQRCode = 'Сформировать уникальный QR код внутри приложения и использовать данный код для пропуска на территорию парковки';
  const textOwn = 'Пожалуйста, опишите свой способ пропускной системы на парковочное место'

  const styleFormControlLabel = {
    width: '33%',
    background: COLOR_PURPLE,
    borderRadius: '0.83vw',
    color: "#fff",
    ':first-of-type': {
      marginLeft: '0px',
    },
    ':last-child': {
      marginRight: '0px',
    },
    margin: '0 10px',
    height: "40vh",
    alignItems: "baseline",
    position: "relative",
    display: 'flex',
    "& span.MuiFormControlLabel-label": {
      width: '100%'
    }
  }

  const paramsRadioButton = {
    sx: styleFormControlLabel,
    labelPlacement: "start",
    control: <Radio 
      icon={<RadioIcon />} 
      checkedIcon={<RadioCheckedIcon />}
      sx={{
        marginTop: '2vh',
        marginRight: '2vh',
        padding: 0
      }} 
    />
  }

  return (<>
    <Typography sx={{
      lineHeight: '1.5',
      letterSpacing: '0.00938em',
      fontSize: '1.24vw', 
      color: '#5D31B8', 
      marginBottom: '3vh'
    }}>
      Выберите удобную для вашей парковки пропускную систему
    </Typography>
    <Box>

      <Controller
        rules={{ required: defaultRequired.accessSystemType.message }}
        render={({ field, fieldState:{error} }) => (<>
          <RadioGroup aria-label="gender" {...field} row sx={{
            flexWrap: 'nowrap',
            marginBottom: '1vh'
          }}>
            <FormControlLabel
              {...paramsRadioButton}
              value="digitalCheck"
              label={<AccessSystemLabel
                title="Цифровой чек"
                type={"digital_check"}
                selectedType={watchAccessSystemType}
                subtitle={textDigitalCheck}
                icon={<DigitalCheckIcon />}
              />}
            />

            <FormControlLabel
              {...paramsRadioButton}
              value="QR_code"
              label={<AccessSystemLabel
                title="QR код"
                type={"qr_code"}
                selectedType={watchAccessSystemType}
                subtitle={textQRCode}
                icon={<QRCodeIcon />}
              />}
            />

            <FormControlLabel
              {...paramsRadioButton}
              value="own"
              label={<AccessSystemLabel
                title="Свое"
                selectedType={watchAccessSystemType}
                type={"own"}
                subtitle={textOwn}
                icon={<OwnIcon />}
              />}
            />

          </RadioGroup>
          {error?.message && <FormHelperText error>{error.message}</FormHelperText>}
        </>)}
        name="accessSystemType"
        control={control}
      />

      {/* {watchAccessSystemType === 'own' ? <AccessSystemOwn/> :
       watchAccessSystemType === 'QR_code' ? <AccessSystemQRCode /> : '' } */}
    </Box>
  </>)
}

export default AccessSystem;