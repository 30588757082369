import FirebaseAuth from './firebase/authFirebase';
import ApiManager from '../utils/apiManager'

export default class AuthService {
  constructor() {
    this.firebaseAuth = new FirebaseAuth();
    this.apiManager = new ApiManager();
  }

  async signUp(data) {
    if(data.password !== data.confirmPassword) return null;
    const newUser = await this.firebaseAuth.createUser(data.email, data.password);
    if(!newUser) return null;

    try {
      await this.firebaseAuth.sendEmailVerification();
      return newUser;
    } catch (error) {
      this.firebaseAuth.deleteUser();
      return null;
    }
  }

  async signIn(data) {
    const user = await this.firebaseAuth.signIn(data.email, data.password);
    console.log('FIREBASE USER: ', user)
    if(!user) return null;
    const response = await this.apiManager.post({
      url: '/api/authorization/rentier',
      data: {
        firebase_token: user.accessToken
      }
    });
  
    return response?.data?.token;
  }

  async resetPassword(email) {
    try {
      await this.firebaseAuth.sendPasswordResetEmail(email);
      return email;
    } catch (error) {
      return null;
    }
  }
}