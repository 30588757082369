import axios from 'axios';
import axiosRetry from 'axios-retry';

export default class ApiManager {
  constructor(timeout=5000) {
    this.axios = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      timeout,
    })
    axiosRetry(this.axios, { retries: 3 });
  }

  async get({url, config}) {
    try {
      const response = await this.axios.get(url, config);
  
      console.log("API SUCCESS: ", response)
      return response;
    } catch (error) {
      console.log("API ERROR: ", error)
      return null;
    }
  };

  async post({url, data={}, config={}}) {
    try {
      const response = await this.axios.post(url, data, config);

      console.log("API SUCCESS: ", response)
      return response;
    } catch (error) {
      console.log("API ERROR: ", error)
      return null;
    }
    
  };

  async patch({url, data={}, config}) {
    try {
      const response = await this.axios.patch(url, data, config);
  
      console.log("API SUCCESS: ", response)
      return response;
    } catch (error) {
      console.log("API ERROR: ", error)
      return null;
    }
  };

  async delete({url, config}) {
    try {
      const response = await this.axios.delete(url, config);
  
      console.log("API SUCCESS: ", response)
      return response;
    } catch (error) {
      console.log("API ERROR: ", error)
      return null;
    }
  };
}