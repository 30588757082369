import React from 'react';
import { Grid, Box,Typography } from '@mui/material';
import {COLOR_PURPLE} from '../../../../constants/appConst';
import {
  OfferIcon,
  RulesIcon,
  PoliticsIcon,
} from '../../SVGs'
import { Link } from "react-router-dom";

const LegalInfo = () => {

  const legalInfo = [{
    name:'offer',
    title: 'Оферта',
    icon: <OfferIcon />,
    href: ''
  }, {
    name: 'politics',
    title: 'Политика конфиденциальности',
    icon: <PoliticsIcon />,
    href: ''
  }, {
    name: 'regulations',
    title: 'Правила сервиса',
    icon: <RulesIcon />,
    href: ''
  }];

  const styleBox = {
    color: '#fff',
    background: COLOR_PURPLE,
    borderRadius: '0.83vw',
    width: '100%',
    ':first-of-type': {
      marginLeft: '0px',
    },
    ':last-child': {
      marginRight: '0px',
    },
    margin: '0 10px',
    marginTop: '4vh',
    height: "40vh",
    alignItems: "baseline",
    position: "relative",
    display: 'flex',
  }

  return (<>
  <Box>
    <Grid container spacing={3}>
      {legalInfo.map((option, i) => (<Grid
        item
        xs={4}
        key={`legalInfo_${option.name}`}
      >
        <Box sx={styleBox}>
          <Box sx={{marginLeft: '1vw', marginTop: '2vh'}}>
            <Box>{option.icon}</Box>
            <Box sx={{position: 'relative', top: '5vh', height: '10vh'}}>
              <Typography sx={{
                fontWeight: 500,
                fontSize: '1.45vw',
                lineHeight: '1.97vw',
              }}>{option.title}</Typography>
            </Box>
            <Box sx={{marginTop: '10vh'}}>
              <Link to={option.href} style={{
                textDecoration: 'none',
                backgroundColor: '#80CC5E',
                padding: '1.03vw 3.11vw',
                display: 'block',
                borderRadius: '0.83vw',
                width: 'fit-content'
              }}>
                <Typography sx={{
                  color: '#fff',
                  textAlign: 'center',
                  fontSize: '0.83vw'
                }}>Читать</Typography>
              </Link>
            </Box>
          </Box>
        </Box>
      </Grid>))}
    </Grid>
  </Box>
  </>)
}

export default LegalInfo;