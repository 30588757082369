import firebaseApp from './initFirebase' 
import { 
  getAuth, 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  deleteUser
} from "firebase/auth";

export default class FirebaseAuth {
  constructor() {
    this.auth = getAuth(firebaseApp);
    this.auth.languageCode = 'ru';
  }

  async createUser(email, password) {
    try {
      const userCredential = await createUserWithEmailAndPassword(this.auth, email, password)
      const user  = userCredential.user;
      return user;
    } catch (error) {
      console.log(error)
      return null;
    }
  }

  async sendEmailVerification() {
    await sendEmailVerification(this.auth.currentUser)
  }

  async sendPasswordResetEmail(email) {
    await sendPasswordResetEmail(this.auth, email)
  }
  
  async signIn(email, password) {
    try {
      const userCredential = await signInWithEmailAndPassword(this.auth, email, password)
      if(!this.auth.currentUser.emailVerified) throw new Error('Email is not verified');
      const user  = userCredential.user;
      return user;
    } catch (error) {
      console.log(error)
      return null;
    }
  }

  async deleteUser() {
    try {
      await deleteUser(this.auth.currentUser);
    } catch (error) {
      console.log(error)
    }
  }
}
