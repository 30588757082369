import React from 'react';

const sizes = {
  width: '3.83vw',
  height: '3.83vw'
}

const SuccessfulRegistration = () => {	return (
<svg style={sizes} viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="37" cy="37" r="37" fill="#80CC5E"/>
<path d="M25.0957 37.3217L32.8174 45.0435L48.9044 28.9565" stroke="white" strokeWidth="5.14783" strokeLinecap="round"/>
</svg>
)
}

export default SuccessfulRegistration;

