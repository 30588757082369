import React from 'react';
import {Box, TextField, Typography} from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';

const AccessSystemOwn = () => {
  const {control} = useFormContext()

  const styleInput = {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#fff",
      borderRadius: '0.83vw',
      height: '6vw',
      '&.Mui-focused fieldset': {
        border: 0,
      },
    },
    "& .MuiOutlinedInput-root:hover": {
      border: 0,
    },
    '& .MuiInputLabel-root': {
      transform: 'none',
      padding: '0.85vw 0.72vw'
    },
    '& label.Mui-focused': {
      display: 'none',
    },
    '& label.MuiFormLabel-filled': {
      display: 'none'
    },
    '& textarea.MuiOutlinedInput-input': {
      fontSize: "0.8vw"
    },
    '& label.MuiInputLabel-root': {
      fontSize: "0.8vw"
    },
    '& fieldset': {
      display: "none"
    },
    "& span.MuiInputLabel-asterisk": {
      display: 'none'
    },
    width: '15vw',
    marginTop: '1vh',
  }
  return (<>
  <Box>
    <Controller
        render={({ field }) => <><TextField
          label="Опишите способ"
          required={true}
          multiline
          rows={3}
          fullWidth
          sx={styleInput}
          {...field}
        /></>}
        name="accessSystemOwn.message"
        control={control}
      />
      <Typography sx={{ fontSize: '0.8vw', marginTop: '2vh' }}>Мы ознакомимся с вашим способом и <br/> свяжемся с Вами</Typography>
    </Box>
  </>)
}

export default AccessSystemOwn;