import React from 'react';
import { Navigate } from "react-router-dom";
import useAuth from '../../hooks/useAuth';

const ProtectedRoute = ({ children, auth }) => {
  const {isAuth} = useAuth(); 
  
  if(!isAuth && auth) {
    return <Navigate to="/login" replace />;
  } 
  else if (isAuth && !auth) {
    return <Navigate to="/deals" replace />;
  }

  return children;
}

export default ProtectedRoute;