import ApiManager from '../utils/apiManager'
import imageCompression from 'browser-image-compression';

export default class ParkingService {
  constructor() {
    this.apiManager = new ApiManager(10000);
    this.compressionOptions = {
      maxSizeMB: 1,
      useWebWorker: true
    }
  }

  async createParking(accessToken, data) {
    const response = await this.apiManager.post({
      url: '/api/user/profile/parking',
      data,
      config: {
        headers: {
          Authorization: `token ${accessToken}`
        },
      },
    });
  
    if(!response) return null;

    if(data?.parkingPhoto) {
      await Promise.all(data.parkingPhoto?.map(async (photo) => {
        const compressedFile = await imageCompression(photo.file, this.compressionOptions);
        let formData = new FormData();
        formData.append('image', compressedFile, photo.file.name);
        formData.append('parking_id', response.data.id);
        formData.append('order', photo.order);
        await this.apiManager.post({
          url: `/api/user/profile/parking/image`,
          data: formData,
          config: {
            headers: {
              Authorization: `token ${accessToken}`,
              'Content-Type': 'multipart/form-data'
            },
          },
        });
      }))
    }
    return response.data;
  }

  async updateParking(accessToken, parkingId, data) {
    const response = await this.apiManager.patch({
      url: `/api/user/profile/parking/${parkingId}`,
      data,
      config: {
        headers: {
          Authorization: `token ${accessToken}`
        },
      },
    });

    if(!response) return null;
  
    if(data?.parkingPhoto) {
      await Promise.all(data.parkingPhoto?.map(async (photo) => {
        const compressedFile = await imageCompression(photo.file, this.compressionOptions);
        let formData = new FormData();
        formData.append('image', compressedFile, photo.file.name);
        formData.append('parking_id', parkingId);
        formData.append('order', photo.order);
        await this.apiManager.post({
          url: `/api/user/profile/parking/image`,
          data: formData,
          config: {
            headers: {
              Authorization: `token ${accessToken}`,
              'Content-Type': 'multipart/form-data'
            },
          },
        });
      }))
    }
    return response.data;
  }

  async deleteParking(accessToken, parkingId) {
    const response = await this.apiManager.delete({
      url: `/api/user/profile/parking/${parkingId}`,
      config: {
        headers: {
          Authorization: `token ${accessToken}`
        },
      },
    });
  
    return response;
  }

  async deleteParkingImage(accessToken, imgUrl) {
    await this.apiManager.delete({
      url: `/api/user/profile/parking/image`,
      config: {
        params: {
          file_name: imgUrl?.replace(`${process.env.REACT_APP_API_BASE_URL}/media/`, '')
        },
        headers: {
          Authorization: `token ${accessToken}`
        },
      },
    });
  }

  async getAllParkings(accessToken) {
    const response = await this.apiManager.get({
      url: '/api/user/profile/parking',
      config: {
        headers: {
          Authorization: `token ${accessToken}`
        },
      },
    });
  
    return response?.data || [];
  }

  async getDeals(accessToken) {
    const response = await this.apiManager.get({
      url: '/api/user/profile/parking/deals',
      config: {
        headers: {
          Authorization: `token ${accessToken}`
        },
      },
    });
  
    return response?.data || [];
  }
}