import ParkingService from "../services/parking.service";

export const fetchDeals = async (accessToken) => {
  const parkingService = new ParkingService();
  let deals = await parkingService.getDeals(accessToken);
  deals = deals.map(deal => {
    deal.price /= 100;
    return deal;
  })
  return deals;
}