

import React, {useState, useEffect} from 'react';
import { Box } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {StyledTableRow, StyledTableCell} from '../styles';
import moment from "moment";
import { DATE_FORMAT } from './../../../constants/appConst';

const columns = [
  { id: 'id', label: 'Id', minWidth: 100 },
  { id: 'address', label: 'Адрес', minWidth: 170 },
  {
    id: 'startTimestamp',
    label: 'Дата создания',
    minWidth: 170,
    align: 'right',
    format: (value) => {
      const dateMoment = value ? moment(value) : null ;
      return dateMoment && dateMoment.format(DATE_FORMAT)
    },
  },
  {
    id: 'endTimestamp',
    label: 'Дата закрытия',
    minWidth: 170,
    align: 'right',
    format: (value) => {
      const dateMoment = value ? moment(value) : null ;
      return dateMoment && dateMoment.format(DATE_FORMAT)
    },
  },
  {
    id: 'carNumber',
    label: 'Гос. номер',
    minWidth: 170,
    align: 'right',
  },
  {
    id: 'price',
    label: 'Сумма(руб.)',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
];

// function createData(name, code, population, size, density, price) {
//   return { name, code, population, size, density, price };
// }

// const rows = [
//   createData('India', 3287263, "2022-01-31T16:34:27.597Z", 'test@fff.ff', "test2@fff.ff", 100),
//   createData('China', 9596961, "2022-01-31T16:34:27.597Z", 'test@fff.ff', "test2@fff.ff", 100),
//   createData('Italy', 301340, "2022-01-31T16:34:27.597Z", 'test@fff.ff', "test2@fff.ff", 100),
//   createData('United States', 9833520, "2022-01-31T16:34:27.597Z", 'test@fff.ff', "test2@fff.ff", 100),
//   createData('Canada', 9984670, "2022-01-31T16:34:27.597Z", 'test@fff.ff', "test2@fff.ff", 100),
//   createData('Australia', 7692024, "2022-01-31T16:34:27.597Z", 'test@fff.ff', "test2@fff.ff", 100),
//   createData('Germany', 357578, "2022-01-31T16:34:27.597Z", 'test@fff.ff', "test2@fff.ff", 100),
//   createData('Ireland', 70273, "2022-01-31T16:34:27.597Z", 'test@fff.ff', "test2@fff.ff", 100),
//   createData('Mexico', 1972550, "2022-01-31T16:34:27.597Z", 'test@fff.ff', "test2@fff.ff", 100),
//   createData('Japan', 377973, "2022-01-31T16:34:27.597Z", 'test@fff.ff', "test2@fff.ff", 100),
//   createData('France', 640679, "2022-01-31T16:34:27.597Z", 'test@fff.ff', "test2@fff.ff", 100),
//   createData('United Kingdom', 242495, "2022-01-31T16:34:27.597Z", 'test@fff.ff', "test2@fff.ff", 100),
//   createData('Russia', 17098246, "2022-01-31T16:34:27.597Z", 'test@fff.ff', "test2@fff.ff", 100),
//   createData('Nigeria', 923768, "2022-01-31T16:34:27.597Z", 'test@fff.ff', "test2@fff.ff", 100),
//   createData('Brazil', 8515767, "2022-01-31T16:34:27.597Z", 'test@fff.ff', "test2@fff.ff", 100),
// ];

export default function DealsTable({onChange, deals}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);

  useEffect(()=> {
    onChange && onChange({page, rowsPerPage})
  }, [onChange, page, rowsPerPage])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  function defaultLabelDisplayedRows({ from, to, count }) { 
    return `${from}–${to} из ${count !== -1 ? count : `более, чем ${to}`}`;
  }

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  const startPage = page * rowsPerPage;

  const result = deals?.slice(startPage, startPage + rowsPerPage) || []
  return (
    <Box>
      <TableContainer sx={{ minHeight: '25vw' }}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, border: 0, fontSize: '0.83vw' }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {result?.map((row) => {
              return (
                <StyledTableRow key={row.id}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <StyledTableCell key={column.id} align={column.align}>
                        {column.format ? column.format(value)
                          : value}
                      </StyledTableCell>
                    );
                  })}
                </StyledTableRow>
              );
            })}
          </TableBody>
      </Table>
    </TableContainer>

    <TablePagination
        // rowsPerPageOptions={[10, 20, 40, 80]}
        // onRowsPerPageChange={handleChangeRowsPerPage}
        // pageSize={7}
        rowsPerPageOptions={[7]}
        component="div"
        count={deals?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        labelDisplayedRows={defaultLabelDisplayedRows}
        backIconButtonProps={{
          style: {
            color: page === 0 ? "rgba(93, 49, 184, 1)" : "#fff",
            padding: '0.5vw',
            backgroundColor: page === 0 ? "#fff" : 'rgba(93, 49, 184, 1)',
            borderRadius: '8px'
          },
        }}
        nextIconButtonProps={{
          style: {
            color: page === Math.floor(deals?.length/rowsPerPage) || (page === 0 && deals?.length/rowsPerPage === 1) ? "rgba(93, 49, 184, 1)" : "#fff",
            padding: '0.5vw',
            backgroundColor: page === Math.floor(deals?.length/rowsPerPage) || (page === 0 && deals?.length/rowsPerPage === 1) ? "#fff" : "rgba(93, 49, 184, 1)",
            borderRadius: '8px'
          },
        }}
        sx={{
          marginTop: '5vh',
          '& p': {
            fontSize: '0.83vw',
          },
          "& .MuiTablePagination-actions": {
            minWidth: '100px',
            display: 'flex',
            justifyContent: 'space-between'
          }
        }}
      />
    </Box>
  );
}
