import { styled } from "@mui/styles"

export const LinkName = styled('span')((props) => ({
  textDecoration: 'none',
  marginLeft: '0.5vw',
  color: '#fff',
  position: 'relative',
  bottom: '0.14vw',
  fontSize: '0.83vw'
}))

export const SidebarTab = styled('div')((props) => ({
  marginLeft: '0.5vw',
  marginRight: '0.5vw',
  backgroundColor: props.selected ? 'rgba(111, 58, 221, 1)' : '',
  borderRadius: '0.5vw 0.5vw 0.5vw 0.5vw',
  padding: '0.5vw 0px 0.5vw 0.5vw',
  transition: 'background-color 0.1s, color 0.1s',
  marginBottom: '1vh',
  '&:hover': {
    backgroundColor: 'rgba(111, 58, 221, 1)',
  }
}))