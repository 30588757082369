import React, { useState } from 'react';
import Page from "../../components/Page"
import AuthService from '../../services/auth.service';
import { Grid, Typography, TextField, Button, Box, FormControl } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import { ContainerForm, BoxInput } from "./styles";
import { EMAIL_PATTERN } from '../../constants/appConst';
import { ParkifyLogo, RegisterIllustration, SuccessIcon } from '../../layouts/SVGs';

const ResetPassword = (props) => {
  const authService = new AuthService();
  const [submitted, setSubmitted] = useState(false);
  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: '',
    }
  });

  const onSubmit = async (data) => {
    console.log(data)
    const response = await authService.resetPassword(data.email)
    if(response) setSubmitted(true);
  };

  const styleInput = {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#fff",
      padding: '0.5vw',
      borderRadius: '0.83vw',
      '&.Mui-focused fieldset': {
        border: 0,
      },
      '& fieldset.MuiOutlinedInput-notchedOutline': {
        borderWidth: '2px',
        borderColor: 'red'
      }
    },
    "& .MuiOutlinedInput-root:hover": {
      border: 0,
    },
    '& label.MuiInputLabel-root': {
      top: '0.5vw',
      left: '0.25vw',
    },
    '& label.Mui-focused': {
      display: 'none'
    },
    '& label.MuiFormLabel-filled': {
      display: 'none'
    },
    '& span.MuiFormControlLabel-label': {
      fontSize: "0.65vw"
    },
    '& fieldset': {
      display: "none"
    },
  }
  const styleButton = {
    backgroundColor: '#80CC5E',
    width: "100%",
    paddingTop: "1.34vw", 
    paddingBottom: "1.34vw", 
    borderRadius: "0.83vw",
    textTransform: 'none'
  };

  const styleMain = {minHeight: "100vh"}

  return <Page title="Восстановление пароля">
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="stretch"
      sx={styleMain}
    >
      <Grid item xs={2}></Grid>
      <Grid item xs={5} alignSelf="flex-end" sx={{display: 'grid', gap: '5vh'}}>
        <Box 
          component="div"
          sx={{ 
            width: "22vw" ,
            display: "flex",
            flexDirection: "column",
            gap: "0.77vw"
          }}
        >
          <ParkifyLogo />
          <Typography variant="h5" sx={{ width: 'max-content', fontSize: '1.24vw' }}>Cдавайте парковочное <br /> место в аренду тысячам PARKIFY <br /> пользователям</Typography>
        </Box>
        <RegisterIllustration />
      </Grid>
      <Grid item xs={5} > 
        <ContainerForm>
          {submitted ?
          <Box sx={{
            height: '10vw',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}>
            <SuccessIcon />
            <Box>
              <Typography sx={{
                fontSize: '1.24vw',
                marginBottom: '0.5vw'
              }}>Восстановление пароля</Typography>
              <Typography sx={{
                fontSize: '0.8vw',
              }}>Мы выслали на Ваш email письмо с <br /> инструкцией, прочитайте его</Typography>
            </Box>
          </Box> :
          <Box sx={{ 
            width: "20vw",
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            alignItems: "center"
          }}>
            <form onSubmit={handleSubmit(onSubmit)} style={{width: "inherit"}}>
              <Typography fontSize="1.24vw" textAlign={'center'}> Восстановление пароля </Typography>
              <br />
              <FormControl fullWidth required>
                <BoxInput>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: true,
                      pattern: {
                        value: EMAIL_PATTERN,
                      }
                    }}
                    render={({ field, fieldState: { error } }) => <TextField
                      label="Email"
                      sx={{
                        ...styleInput,
                        '& fieldset': {
                          display: error ? 'block' : 'none',
                        }
                      }}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: false,
                        disableAnimation: true
                      }}
                      fullWidth
                      error={error}
                      {...field}
                    />}
                  />
                </BoxInput>
                <BoxInput>
                  <Button
                    variant="contained"
                    type="submit"
                    sx={styleButton}
                  >Далее</Button>
                </BoxInput>
              </FormControl>
            </form>
          </Box> }
        </ContainerForm>
      </Grid>
    </Grid>
  </Page>
}

export default ResetPassword;