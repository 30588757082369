import { Box, Typography } from '@mui/material';
import React from 'react';

const ParkingPlace = ({title, icon}) => (
  <Box sx={{
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'start',
    marginLeft: '1vw',
    justifyContent: 'space-between',
    minHeight: '12vh',
  }}>
    {icon}
    <Typography sx={{ fontSize: '15px' }}>{title}</Typography>
  </Box>
)
export default ParkingPlace;